.ql-container.ql-snow,
.ql-toolbar.ql-snow {
  border: none !important;
}

.ql-toolbar.ql-snow {
  border-bottom: solid 1px black !important;
}

.table-row:hover {
  background-color: #f0f0f0 !important; /* Light grey background */
  cursor: pointer;
}
