@import url(https://fonts.googleapis.com/css?family=Roboto:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #131417;
  font-family: "Poppins", sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;
}

/* CSS để ẩn icon "mắt" trong các trường nhập mật khẩu */
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}
